<template>
  <v-container>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">{{
          $t('dialogMessages.itemDeleteConfirmation')
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">{{
            $t('buttons.cancel')
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{
            $t('buttons.ok')
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col offset="3" cols="4" sm="4">
        <v-autocomplete
          v-if="clients"
          v-model="clientId"
          :items="selectClients"
          @change="selectClient"
          value="clientId"
          :label="$t('wizard.selectCompany')"
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col align-self="center">
        <v-btn
          color="primary"
          dark
          @click="dialog = !dialog"
          :disabled="clientId == null"
        >
          {{ $t('wizard.createNewInsuredPerson') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="insuredPersons"
          :loading="isLoading"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-col>
                      <v-text-field
                        v-model="editedItem.name"
                        :label="$t('wizard.name') + '*'"
                        clearable
                        :error-messages="nameErrors"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-menu
                        v-model="datepicker_show"
                        :close-on-content-click="true"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="editedItem.birthDate"
                            :label="$t('wizard.birthDate')"
                            :rules="dateRule"
                            v-on="on"
                            dense
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedItem.birthDate"
                          @input="datepicker_show = false"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="editedItem.phone"
                        :label="$t('wizard.phone')"
                        clearable
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="editedItem.relativeName"
                        :label="$t('wizard.relativeName')"
                        clearable
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="editedItem.relativePhone"
                        :label="$t('wizard.relativePhone')"
                        clearable
                      ></v-text-field>
                    </v-col>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    {{ $t('wizard.cancel') }}
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save">
                    {{ $t('wizard.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:item.birthDate="{ item }">
            <span>
              {{ formatDate(item.birthDate) }}
            </span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import dayjs from 'dayjs'

export default {
  data: function () {
    return {
      isLoading: true,
      dialog: false,
      dialogDelete: false,
      datepicker_show: false,
      nameRequired: false,
      editedIndex: -1,
      editedItem: {},
      defaultItem: {
        name: '',
        birthDate: '',
        phone: '',
        relativeName: '',
        relativePhone: '',
      },
      selectClients: [],
      clientId: null,
      headers: [
        {
          text: this.$i18n.t('headers.name'),
          value: 'name',
        },
        {
          text: this.$i18n.t('headers.birthDate'),
          value: 'birthDate',
        },
        {
          text: this.$i18n.t('headers.phone'),
          value: 'phone',
        },
        {
          text: this.$i18n.t('headers.relativeName'),
          value: 'relativeName',
        },
        {
          text: this.$i18n.t('headers.relativePhone'),
          value: 'relativePhone',
        },
        {
          text: this.$i18n.t('headers.actions'),
          value: 'actions',
        },
      ],
      dateRule: [
        (value) =>
          /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/.test(value) ||
          this.$i18n.t('wizard.dateFormatValidation'),
      ],
    }
  },
  methods: {
    selectClient(value) {
      this.$store.dispatch('insuredPerson/getInsuredPersons', value)
    },
    deleteItem(item) {
      this.editedIndex = item.id
      this.editedItem = Object.assign({}, item)
      this.editItem.birthDate = this.dialogDelete = true
    },
    deleteItemConfirm() {
      this.$store.dispatch('insuredPerson/deleteInsuredPerson', {
        clientId: this.clientId,
        insuredPersonId: this.editedIndex,
      })
      this.closeDelete()
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    editItem(item) {
      this.editedIndex = this.insuredPersons.indexOf(item)
      this.editedItem = Object.assign({}, item)
      if (item.birthDate)
        this.editedItem.birthDate = this.formatDate(item.birthDate)
      this.dialog = true
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    formatDate(date) {
      return dayjs(date).format('YYYY-MM-DD')
    },
    save() {
      const insuredPerson = Object.assign({}, this.editedItem)
      if (insuredPerson.name == '' || !insuredPerson.name) {
        this.nameRequired = true
      } else {
        this.nameRequired = false

        insuredPerson.clientId = parseInt(this.clientId)
        insuredPerson.birthDate = dayjs(insuredPerson.birthDate)
        if (this.editedIndex > -1) {
          this.$store.dispatch(
            'insuredPerson/updateInsuredPerson',
            insuredPerson
          )
        } else {
          this.$store.dispatch(
            'insuredPerson/createInsuredPerson',
            insuredPerson
          )
        }
        this.close()
      }
    },
  },
  mounted() {
    this.$store.dispatch('client/getClients')
  },
  created() {
    this.editedItem = Object.assign({}, this.defaultItem)
  },
  computed: {
    ...mapState({
      clients: (state) => state.client.clients,
      insuredPersons: (state) => state.insuredPerson.insuredPersons,
    }),
    formTitle() {
      return this.editedIndex === -1
        ? this.$i18n.t('forms.newInsuredPerson')
        : this.$i18n.t('forms.editInsuredPerson')
    },
    nameErrors() {
      const errors = []
      this.nameRequired && errors.push(this.$i18n.t('validations.nameRequired'))
      return errors
    },
  },
  watch: {
    clients() {
      this.selectClients = this.clients.map((c) => ({
        text: c.name,
        value: c.id,
      }))
    },
    insuredPersons() {
      this.isLoading = false
    },
  },
  destroyed() {
    this.$store.dispatch('insuredPerson/clearInsuredPersons')
    this.$store.dispatch('client/clearClients')
  },
}
</script>

<style lang="scss" scoped></style>
